"use client";

import * as React from "react";
import { Check, ChevronDown, Copy, MailPlus } from "lucide-react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@heroui/dropdown";
import { Button } from "@heroui/button";
import { notify } from "@/components/ui/toast";
import { motion, AnimatePresence } from "motion/react";

export function ContactMe() {
  const [copied, setCopied] = React.useState(false);
  const email = "karl@karlkoch.me";

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          className="w-full md:w-[156px]"
          color={copied ? "success" : "primary"}
          name="Copy email to clipboard"
          endContent={
            copied ? (
              <Check size={16} className="shrink-0" />
            ) : (
              <ChevronDown size={16} className="shrink-0" />
            )
          }
        >
          <div className="relative flex h-full w-full items-center">
            <AnimatePresence mode="popLayout" initial={false}>
              {copied ? (
                <motion.div
                  key="copied"
                  initial={{ y: -30 }}
                  animate={{ y: 0 }}
                  exit={{ y: 30 }}
                  className="flex h-full items-center"
                >
                  Copied!
                </motion.div>
              ) : (
                <motion.div
                  key="email"
                  initial={{ y: -30 }}
                  animate={{ y: 0 }}
                  exit={{ y: 30 }}
                  className="flex h-full items-center"
                >
                  {email}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Contact Me"
        onAction={(key) => {
          if (key === "copy") {
            notify({ content: email, setCopied });
          } else if (key === "send") {
            window.location.href = `mailto:${email}?subject=Hello!`;
          }
        }}
      >
        <DropdownItem
          key="copy"
          aria-label="Copy email to clipboard"
          startContent={<Copy size={16} />}
        >
          Copy email
        </DropdownItem>
        <DropdownItem key="send" startContent={<MailPlus size={16} />}>
          Send email
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
